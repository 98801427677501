import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  suppliers: [],
  allCompanies: [],
  data: [],
  initialCompanies: [],
  finderAddress: "",
  finderAddressObj: {},
};

const SuppliersSlice = createSlice({
  name: "Suppliers",
  initialState,
  reducers: {
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    setAllCompanies(state, action) {
      state.allCompanies = action.payload;
    },
    setInitialCompanies(state, action) {
      state.initialCompanies = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setFinderAddress(state, action) {
      state.finderAddress = action.payload;
    },
    setFinderAddressObj(state, action) {
      state.finderAddressObj = action.payload;
    },
  },
});

export const {
  setSuppliers,
  setAllCompanies,
  setInitialCompanies,
  setData,
  setFinderAddress,
  setFinderAddressObj,
} = SuppliersSlice.actions;

export default SuppliersSlice.reducer;
